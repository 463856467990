import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Router } from '@angular/router';

@Component({
  selector: 'app-channels-section',
  templateUrl: './channels-section.component.html',
  styleUrls: ['./channels-section.component.scss']
})
export class ChannelsSectionComponent implements OnInit {

  config: SwiperOptions = {
    slidesPerView: 1.0,
    pagination: { el: '.swiper-pagination', clickable: true },
    navigation: {
      nextEl: '',
      prevEl: ''
    },
    spaceBetween: 30,
    breakpoints: {
      640: {
        slidesPerView: 1.0,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 2.0,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 1.0,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 1.1,
        spaceBetween: 30,
      },
      1300: {
        slidesPerView: 2.0,
        spaceBetween: 30,
      }
    }
  };

  constructor(
    private gtmService: GoogleTagManagerService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  public portalButtonGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:home:canais',
      action: 'clicou',
      label: 'card:canal-portal',
    };
    this.gtmService.pushTag(gtmTag);
    const returnIpanema = JSON.parse(sessionStorage.getItem('returnIpanema'));
    if (returnIpanema) {
      this.router.navigate(['painel']);
    } else {
      this.router.navigate(['entrar']);
    }
  }

  public centralButtonGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:home:canais',
      action: 'clicou',
      label: 'card:canal-central',
    };
    this.gtmService.pushTag(gtmTag);
  }

  public whatsappButtonGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:home:canais',
      action: 'clicou',
      label: 'card:canal-whatsapp',
    };
    this.gtmService.pushTag(gtmTag);
  }

  public chatButtonGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:home:canais',
      action: 'clicou',
      label: 'card:canal-chat',
    };
    this.gtmService.pushTag(gtmTag);
  }

}
